import 'slick-carousel';
import 'lodash';

class Vacancies {
    constructor() {
        this.$slider = $('.js-vacancies');
        this.$vacancy = $('.js-vacancy');
        this.vacancyTemplate = $('#vacancy-template').html();
        this.init();
    }

    init() {

        this.openVacancy();

        $(document).on('click', '.js-vacancy-button', (e) => {
            this.setModalInfo($(e.target));
        });

        this.$slider.slick({
            slidesToShow: 3,
            infinite: false,
            dots: true,
            arrows: true,
            swipe: false,
            touchMove: false,
            responsive: [
                {
                    breakpoint: 1240,
                    settings: {
                        slidesToShow: 2,
                        arrows: false,
                        swipe: true,
                        touchMove: true
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        arrows: false,
                        swipe: true,
                        touchMove: true
                    }
                },
                {
                    breakpoint: 620,
                    settings: {
                        slidesToShow: 1,
                        arrows: false,
                        swipe: true,
                        touchMove: true
                    }
                }
            ]
        });
    }

    setModalInfo($element) {
        let $info = $element.closest('.js-vacancy-item').find('.js-vacancy-info'),
            data = $info.data();

        data.description = $info.html();

        let templateFn = _.template(this.vacancyTemplate);

        $('input[name=vacancy]').val(data.vacancy);

        this.$vacancy.html(templateFn(data));
    }

    openVacancy() {
        let vacancyId = $('body').data('vacancyId');

        if(!vacancyId) return false;

        let $vacancies = $('#vacancies'),
            $vacancy = $('#' + vacancyId);

        setTimeout(function () {
            $("html, body").animate({scrollTop: $vacancies.offset().top}, 1000, function () {
                $vacancy.find('.button').trigger('click');
            });
        }, 1000);
    }
}

let vacancies = new Vacancies();

export default vacancies;