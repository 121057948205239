import 'parsleyjs/src/parsley';
import 'inputmask/dist/inputmask/jquery.inputmask';
import 'inputmask';

$(() => {
    $('.js-mask-phone').inputmask({
        mask: '+7 (999) 999-99-99'
    });

    $(document).on('submit', '.js-form', function (e) {
        e.preventDefault();
        let $form = $(this),
            $modal = $form.closest('.modal'),
            $button = $form.find('[type="submit"]');

        $form.parsley().validate();

        if ($form.parsley().isValid()) {
            let data = new FormData($form[0]);
            $button.prop('disabled', true);

            callCounterLead();

            $.ajax({
                url: '/ajax.php',
                type: 'POST',
                data: data,
                dataType: 'json',
                contentType: false,
                processData: false,
                error: function (data) {
                    $button.prop('disabled', false);
                }
            }).done(function (data) {
                $modal.find('.modal-content').append(data['html']);
                $modal.find('.modal-body').hide();

                $modal.on('hidden.bs.modal', function () {
                    $modal.find('.modal-body').show();
                    $modal.find('.modal-content').find('.form-result').remove();
                    $button.prop('disabled', false);
                });
            });
        }
    });

    $(document).on('input change', '.js-input-file', function () {

        let $input = $(this),
            value = $input.val(),
            arr = value.split('\\');

        if (value.length) value = arr[arr.length - 1];

        $input.closest('.input-file').find('.input-file__title').html(value ? value : 'Прикрепить резюме <span>(.pdf; .doc)</span>');
    });

    window.Parsley
        .addValidator('phone', {
            requirementType: 'string',
            validateString: function (value, requirement, field) {
                return field.$element.inputmask("isComplete");
            }
        }).addValidator('filemimetypes', {
        requirementType: 'string',
        validateString: function (value, requirement, parsleyInstance) {

            let file = parsleyInstance.$element[0].files;

            if (file.length === 0) {
                return true;
            }

            let allowedMimeTypes = requirement.replace(/\s/g, "").split(',');
            return allowedMimeTypes.indexOf(file[0].type) !== -1;

        }
    });
});