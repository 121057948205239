class Menu {
    constructor() {
        this.$menuButton = $('.js-menu-toggle');
        this.$menuLinks = $('.js-menu-links a');
        this.$header = $('.header');
        this._scrollbarWidth = 0;
        this.isOpen = false;
        this.time = 0;

        this.init();
    }
    init() {
        this.scrollbarWidth();

        $(window).resize(this.scrollbarWidth.bind(this));

        this.$menuButton.click(this.toggleMenu.bind(this));
        this.$menuLinks.click(this.toggleMenu.bind(this));
    }
    open() {
        $('body').addClass('menu-open').css({
            'paddingRight': this._scrollbarWidth,
            'overflow': 'hidden'
        });
        this.$header.css('paddingRight', this._scrollbarWidth);
        this.isOpen = true;
    }
    close() {
        $('body').removeClass('menu-open');
        this.time = setTimeout(() => {
            $('body').attr("style","");
            this.$header.attr("style","");
        }, 1000);
        this.isOpen = false;
    }
    toggleMenu() {
        clearTimeout(this.time);
        if(this.isOpen) {
            this.close();
        } else {
            this.open();
        }
    }
    scrollbarWidth() {
        if (typeof document === 'undefined') {
            return 0
        }

        let body = document.body,
            box = document.createElement('div'),
            boxStyle = box.style,
            width;

        boxStyle.position = 'absolute';
        boxStyle.top = boxStyle.left = '-9999px';
        boxStyle.width = boxStyle.height = '100px';
        boxStyle.overflow = 'scroll';

        body.appendChild(box);

        width = box.offsetWidth - box.clientWidth;

        body.removeChild(box);

        this._scrollbarWidth = width;
    }
}

let menu = new Menu();

export default menu;