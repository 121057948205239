class Share {

    constructor() {
        this.url = $('meta[property="og:url"]').attr('content');
        this.title = $('meta[property="og:title"]').attr('content');
        this.image = $('meta[property="og:image"]').attr('content');
        this.text = $('meta[property="og:description"]').attr('content');
    }

    vk(data) {
        let url = 'http://vkontakte.ru/share.php?';

        url += 'url=' + encodeURIComponent(data.url);
        url += '&title=' + encodeURIComponent(data.title);
        url += '&description=' + encodeURIComponent(this.text);
        url += '&image=' + encodeURIComponent(this.image);
        url += '&noparse=true';

        this.popup(url);
    }

    fb(data) {
        let url = 'http://www.facebook.com/sharer.php?s=100';

        url += '&p[url]=' + encodeURIComponent(data.url);

        this.popup(url);
    }

    tw(data) {
        let url = 'http://twitter.com/share?';

        url += 'text=' + encodeURIComponent(data.title);
        url += '&url=' + encodeURIComponent(data.url);
        url += '&counturl=' + encodeURIComponent(data.url);

        this.popup(url);
    }

    popup(url) {
        let screenLeft = 0 !== window.screenLeft ? window.screenLeft : window.screen.availLeft,
            screenTop = 0 !== window.screenTop ? window.screenTop : window.screen.availTop,
            width = document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width,
            height = document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height,
            left = (window.innerWidth ? window.innerWidth : width) / 2 - 250 + screenLeft,
            top = (window.innerHeight ? window.innerHeight : height) / 2 - 250 + screenTop;

        window.open(url, '', 'scrollbars=yes, width=500, height=500, top=' + top + ', left=' + left);
    }
}

let share = new Share();

export default share;