import 'slick-carousel';

class Review {
    constructor() {
        this.$slider = $('.js-review');
        this.$sliderNav = $('.js-review-nav');
        this.$sliderPagination = $('.js-review-pagination');

        this.init();
    }

    init() {
        this.$slider.slick({
            slidesToShow: 1,
            infinite: true,
            fade: true,
            dots: true,
            swipe: false,
            touchMove: false,
            appendArrows: this.$sliderNav,
            appendDots: this.$sliderPagination,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        adaptiveHeight: true,
                        arrows: false,
                        swipe: true,
                        touchMove: true,
                    }
                }
            ]
        });
    }
}

let review = new Review();

export default review;