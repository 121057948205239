import '../css/app.scss';
import 'bootstrap';
import device from 'current-device';

import './menu';
import share from './share';
import './vacancies';
import './steps';
import './review';
import './form';

if(device.desktop()) {
    window.WOW = require('wowjs').WOW;

    new WOW({
        live :  false
    }).init();
}

$("body").addClass('is-ready');

$(document).on('click', '.js-modal-toggle', function () {
   let $element = $(this),
       modalOpen = $element.data('open'),
       modalClose = $element.data('close');

   $(modalClose).modal('hide');

   setTimeout(function () {
       $(modalOpen).modal('show');
   }, 500);
});

$(document).on('click', '.js-share', function (e) {
    e.preventDefault();
    share[$(this).data('share')]($(this).data());
});