import 'slick-carousel';

class Steps {
    constructor() {
        this.$slider = $('.js-steps');
        this.$sliderNav = $('.js-steps-nav');
        this.$sliderPagination = $('.js-steps-pagination');
        this.$container = $('.js-steps-container');
        this.$image = $('.js-steps-image');
        this.init();
    }

    init() {
        this.positionImage();
        this.positionSlider();

        $(window).resize(this.positionImage.bind(this));
        $(window).resize(this.positionSlider.bind(this));


        this.$slider.on('init', (event, slick) => {
            let $currentSlide = $(slick.$slides.get(0));

            this.opacitySlides($currentSlide);
        });

        this.$slider.slick({
            infinite: false,
            centerMode: true,
            dots: true,
            autoplay: false,
            variableWidth: true,
            swipe: false,
            touchMove: false,
            appendArrows: this.$sliderNav,
            appendDots: this.$sliderPagination,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        centerMode: false,
                        arrows: false,
                        swipe: true,
                        touchMove: true,
                    }
                }
            ]
        });

        this.$slider.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
            let $nextSlide = $(slick.$slides.get(nextSlide));

            this.opacitySlides($nextSlide);
        });
    }
    opacitySlides($slide) {
        let $prevSlides = $slide.prevAll(),
            $nextSlides = $slide.nextAll();

        $slide.css('opacity', 1);

        $prevSlides.each(function (index, element) {
            $(element).css('opacity', 0.7 - index * 0.2)
        });

        $nextSlides.each(function (index, element) {
            $(element).css('opacity', 0.7 - index * 0.2)
        });
    }
    positionImage() {
        this.$image.css('left', this.$container[0].getBoundingClientRect().left * 0.7 + "px");
    }
    positionSlider() {
        this.$slider.css('marginLeft', -($(window).width() / 2 - this.$container[0].getBoundingClientRect().left * 0.85) + "px");
    }
}

let steps = new Steps();

export default steps;